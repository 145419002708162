



































































































































































import { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import {
  getUserTransactionsTotalAmount,
  getOperatorTransactionsList,
  getUserTransactionsList,
  getOperatorTransactionsListCSVReport,
  getUserTransactionsListCSVReport
} from '@/api/Transaction';
import {
  TransactionsTypes,
  TransactionsTypeNaming,
  UserTransactionsParamsProps,
  UserTransactionsTotalAmountRequestParams,
  UserTransactionsListRequestParams,
  OperatorTransactionsListRequestParams,
  TransactionsListResponse,
  ArrayItem,
  UserTransactionsListCSVRequestParams,
  OperatorTransactionsListCSVRequestParams
} from '@/api/schema';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import StatsCard from '@/components/StatsCard.vue';
import formatDate from '@/helpers/formatDate';
import { textOverflowMiddle } from '@/helpers/formatString';
import { doDownload } from '@/helpers/download';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { DateTime } from 'luxon';

const defaultDates = {
  from: DateTime.now().toFormat('yyyy-LL-dd'),
  to: DateTime.now().toFormat('yyyy-LL-dd')
};

export default {
  name: 'TransactionsTableCard',
  components: {
    VExtendedDataPicker,
    StatsCard
  },

  props: {
    paramsProps: {
      type: Object as PropType<UserTransactionsParamsProps>,
      default: (): UserTransactionsParamsProps => ({})
    },
    operatorUserIdProps: {
      type: String,
      default: (): string => ''
    },
    operatorUserCreatedAtProps: {
      type: String,
      default: (): string => ''
    },
    userTransactionsTab: {
      type: Boolean,
      default: false
    }
  },

  data(): any {
    return {
      TransactionsTypeNaming,
      page: 1,
      type: null,
      operatorUserId: '',
      lastDate: [],
      dates: { ...defaultDates },
      loadingTransactions: false,
      loadingCsv: false,
      loadingTotalAmount: false,
      transactions: [],
      totalAmount: 0,
      showTotalAmount: false,
      filterMenu: false,
      hasNext: false
    };
  },

  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('TokensInfo', ['tokenPrice']),
    ...mapState('OperatorPayments', ['balance']),
    isByUserSearch(): boolean {
      return !this.emptySearchQuery || this.userTransactionsTab;
    },
    routeParams(): UserTransactionsParamsProps | null {
      return this.$route.params.paramsProps || null;
    },
    operatorUserIdInput: {
      get(): string {
        return this.operatorUserId;
      },
      set(newVal: string): void {
        if (!newVal) {
          this.dates = { ...defaultDates };
          this.type = null;
        }

        this.operatorUserId = newVal;
      }
    },
    paginationLength(): number {
      return this.page + (this.hasNext ? 1 : 0);
    },
    tableHeaders(): { [key: string]: string | boolean }[] {
      return [
        {
          text: 'Created At (UTC)',
          value: 'date',
          width: '150px'
        },
        {
          text: 'Transaction Type',
          value: 'actionType',
          width: '20%'
        },
        {
          text: 'Operator User ID',
          value: 'operatorUserId',
          width: '20%'
        },
        {
          text: 'Amount',
          value: 'amount',
          width: '20%'
        },
        {
          text: 'Overall Balance',
          value: 'userWalletBalance',
          width: '20%'
        },
        {
          text: 'Hash',
          value: 'hash',
          width: '20%'
        }
      ];
    },
    emptySearchQuery(): boolean {
      return !this.operatorUserId;
    },
    excludedTypes(): string[] {
      return (
        Object.entries({
          [TransactionsTypes.BETTING_REWARD]: true,
          [TransactionsTypes.BETTING_ROLLBACK]: true,
          [TransactionsTypes.MINT]: true,
          [TransactionsTypes.LOYALTY_SWAP]:
            !this.balance.loyaltySwapFeeActive,
          [TransactionsTypes.BETTING_ROLLBACK_COPIED]: true,
          [TransactionsTypes.BETTING_REWARD_COPIED]: true
        })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_, exclude]) => exclude)
          .map(([type]) => type)
      );
    },
    transactionTypeOptions(): ArrayItem[] {
      return Object.keys(TransactionsTypes)
        .filter((key) => !this.excludedTypes.includes(key))
        .map((key) => ({
          value: key,
          text: TransactionsTypeNaming[key]
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    }
  },

  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId && !this.userTransactionsTab) {
          this.$nextTick((): void => this.getData());
        }
      },
      immediate: true
    },
    emptySearchQuery(val: boolean): void {
      if (!val) return;
      this.getData();
    },
    operatorUserIdProps: {
      handler(newId: string | null): void {
        if (newId) {
          this.operatorUserId = newId;
          this.dates.from = this.operatorUserCreatedAtProps.split(' ')[0];

          if (this.routeParams) return;

          this.$nextTick((): void => {
            this.getData();
          });
        }
      },
      immediate: true
    },
    routeParams: {
      handler(paramsProps: UserTransactionsParamsProps | null): void {
        if (paramsProps) {
          this.setParams(paramsProps);

          this.getData();
        }
      },
      immediate: true
    }
  },

  mounted(): void {
    this.setParams(this.paramsProps);
  },

  methods: {
    textOverflowMiddle,
    formatDate,
    copyToClipBoard,
    getDate(date: string): string {
      return formatDate(
        date.slice(0, date.lastIndexOf('.')),
        'yy.MM.dd HH:mm',
        true,
        false
      );
    },
    submitForm(): void {
      this.resetPagination();
      this.$nextTick((): void => {
        this.getData();
      });
    },
    applyTypes(status: boolean): void {
      if (status === false) {
        this.type = null;
      }

      this.filterMenu = false;
      this.submitForm();
    },
    onDateChange(dates: string[]): void {
      this.dates = dates;
      this.resetPagination();
      this.getData();
    },
    setParams(params: UserTransactionsParamsProps): void {
      const { operatorUserId, type, dates } = params;

      if (operatorUserId) {
        this.operatorUserId = operatorUserId;
      }

      if (dates?.from && dates?.to) {
        this.dates = dates;
      }

      if (type && !this.excludedTypes.includes(type)) {
        this.type = type;
      }
    },
    getData(date?: string): Promise<TransactionsListResponse> {
      this.loadingTransactions = true;
      this.loadingTotalAmount = true;

      const handler = !this.isByUserSearch
        ? getOperatorTransactionsList
        : getUserTransactionsList;

      let params:
        | OperatorTransactionsListRequestParams
        | UserTransactionsListRequestParams = {
        size: 10,
        from: `${this.dates.from}T00:00:00.000`,
        to: date || `${this.dates.to}T23:59:59.999`,
        ...(this.type && { type: String(this.type) as TransactionsTypes }),
        ...(this.isByUserSearch && {
          operatorUserId: this.operatorUserId
        })
      };

      return handler(
        params as
          | OperatorTransactionsListRequestParams
          | UserTransactionsListRequestParams
      )
        .then((data: TransactionsListResponse) => {
          this.transactions = data.content;
          this.hasNext = !data.last;

          if (this.isByUserSearch) {
            return this.getTotalAmount();
          }
          this.showTotalAmount = false;
        })
        .catch((error) => {
          this.transactions = [];
          this.hasNext = false;
          this.showTotalAmount = false;

          errorToastMessage(error);
          console.error(error);
        })
        .finally((): void => {
          this.loadingTransactions = false;
          this.loadingTotalAmount = false;
        });
    },
    changePage(page: number): void {
      const listLength = this.transactions?.length;
      const date =
        page === 1
          ? null
          : this.lastDate[page] || this.transactions[listLength - 1].date;

      this.getData(date).then((): void => {
        this.page = page;
        page === 1 ? (this.lastDate = []) : (this.lastDate[page] = date);
      });
    },
    getCsvReport(): void {
      this.loadingCsv = true;

      const handler = !this.isByUserSearch
        ? getOperatorTransactionsListCSVReport
        : getUserTransactionsListCSVReport;

      let params:
        | OperatorTransactionsListCSVRequestParams
        | UserTransactionsListCSVRequestParams = {
        from: `${this.dates.from}T00:00:00.000`,
        to: `${this.dates.to}T23:59:59.999`,
        ...(this.type && { type: String(this.type) as TransactionsTypes }),
        ...(this.isByUserSearch && {
          operatorUserId: this.operatorUserId
        })
      };

      handler(
        params as
          | OperatorTransactionsListCSVRequestParams
          | UserTransactionsListCSVRequestParams
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response?.data]));
          doDownload(url, `Stats report.csv`);
        })
        .catch((error) => {
          errorToastMessage(error);
          console.error(error);
        })
        .finally(() => {
          this.loadingCsv = false;
        });
    },
    getTotalAmount(): Promise<void> {
      this.showTotalAmount = true;

      if (this.transactions.length) {
        const params: UserTransactionsTotalAmountRequestParams = {
          operatorUserId: this.operatorUserId,
          from: `${this.dates.from}T00:00:00.000`,
          to: `${this.dates.to}T23:59:59.999`,
          ...(this.type && { type: String(this.type) as TransactionsTypes })
        };

        return getUserTransactionsTotalAmount(params)
          .then((data) => {
            this.totalAmount = data.data;
          })
          .catch((error) => {
            errorToastMessage(error);
            console.error(error);
          });
      } else {
        this.totalAmount = 0;
        return Promise.resolve();
      }
    },
    resetPagination(): void {
      this.page = 1;
      this.lastDate = [];
    }
  }
};
