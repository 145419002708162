var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[(_vm.showTotalAmount)?_c('StatsCard',{staticClass:"mr-12",attrs:{"icon":"mdi-cash-fast","label":"Total amount","is-loading":_vm.loadingTotalAmount,"value":((_vm.$options.filters.numeralSpaces(_vm.totalAmount,'0,0.[00]')) + " " + _vm.tokenSymbol)}}):_vm._e(),_c('v-spacer'),(!_vm.userTransactionsTab)?_c('v-text-field',{staticClass:"mr-4",staticStyle:{"max-width":"372px"},attrs:{"placeholder":"Search by Operator User ID","hide-details":"","clearable":"","outlined":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submitForm.apply(null, arguments)}},model:{value:(_vm.operatorUserIdInput),callback:function ($$v) {_vm.operatorUserIdInput=$$v},expression:"operatorUserIdInput"}}):_vm._e(),(!_vm.userTransactionsTab)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitForm}},[_vm._v("Search")]):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","left":"","nudge-bottom":"4","min-width":"326","close-on-content-click":false,"content-class":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"rounded ml-4 px-1",attrs:{"min-width":"38","outlined":"","color":_vm.filterMenu || _vm.type ? 'primary' : ''},on:{"click":function($event){_vm.filterMenu = true}}},on),[_c('v-icon',[_vm._v("mdi-filter-outline")])],1)]}}]),model:{value:(_vm.filterMenu),callback:function ($$v) {_vm.filterMenu=$$v},expression:"filterMenu"}},[_c('h6',{staticClass:"mt-4 mb-2 mx-3 text-body-1 font-weight-bold"},[_vm._v("Transaction Type")]),_c('v-list',{staticClass:"overflow-y-auto py-0",attrs:{"max-height":"360"}},[_c('v-list-item-group',{model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},_vm._l((_vm.transactionTypeOptions),function(option){return _c('v-list-item',{key:option.value,staticClass:"px-2",attrs:{"value":option.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary","dense":""}})],1),_c('v-list-item-content',{staticClass:"text-body-1"},[_vm._v(_vm._s(option.text))])]}}],null,true)})}),1)],1),_c('div',{staticClass:"px-2 pb-2"},[_c('v-btn',{staticClass:"rounded mt-2",attrs:{"block":"","color":"primary"},on:{"click":_vm.applyTypes}},[_vm._v("Apply")]),_c('v-btn',{staticClass:"rounded mt-2",attrs:{"block":"","outlined":"","color":"secondary"},on:{"click":function($event){return _vm.applyTypes(false)}}},[_vm._v("Clear")])],1)],1),_c('v-extended-data-picker',{staticClass:"ml-4",attrs:{"max-today":"","range":"","display-format":"dd MMMM"},on:{"input":_vm.onDateChange},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}),_c('v-btn',{staticClass:"pa-0 ml-4",attrs:{"elevation":"0","color":"primary","outlined":"","loading":_vm.loadingCsv,"disabled":_vm.loadingCsv},on:{"click":_vm.getCsvReport}},[_vm._v("CSV"),_c('v-icon',{staticClass:"ml-2 mr-0",attrs:{"left":""}},[_vm._v("mdi-file-download-outline")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.transactions,"loading":_vm.loadingTransactions,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actionType",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.TransactionsTypeNaming[item.actionType]))]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(_vm._s(_vm._f("numeralSpaces")(item.amount,'0,0.[00]'))),_c('div',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.tokenSymbol))])])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getDate(item.date)))]}},{key:"item.operatorUserId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block black--text",staticStyle:{"min-width":"106px"}},'div',attrs,false),on),[_vm._v(_vm._s(_vm.textOverflowMiddle(item.operatorUserId, 7, 5)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.operatorUserId))])]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.copyToClipBoard(item.operatorUserId)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)],1)]}},{key:"item.userWalletBalance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("numeralSpaces")(item.userWalletBalance,'0,0.[00]'))+" "+_vm._s(_vm.tokenSymbol))])]}},{key:"item.hash",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block black--text",staticStyle:{"min-width":"60px"}},'div',attrs,false),on),[_vm._v(_vm._s(_vm.textOverflowMiddle(item.hash, 3, 3)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.hash))])]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.copyToClipBoard(item.hash)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)],1)]}}])}),_c('v-pagination',{attrs:{"value":_vm.page,"length":_vm.paginationLength,"total-visible":"3"},on:{"input":_vm.changePage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }